/**
 * @method convertTruncateText - add "..." with a limitation
 * @param {*} source
 * @param {*} size
 */
const convertTruncateText = (source, size) => {
  if (source !== null || source !== undefined) {
    return source.length > size ? source.slice(0, size - 1) + '…' : source;
  } else return source;
};

const formatDate = (date) => {
  const handleDate = new Date(date);

  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric'
  });
  return dateTimeFormat.format(handleDate);
};
// YYYY-MM-DD
function formatD(date) {
  const curr = new Date(date);
  const year = curr.getFullYear();
  const month = String(curr.getMonth() + 1).padStart(2, '0');
  const day = String(curr.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const getCurrentMonthDates = () => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return { firstDay: formatD(firstDay), lastDay: formatD(lastDay) };
};

const getCurrentWeekDates = () => {
  const date = new Date();
  const currentDay = date.getDay(); // 0 (Sunday) to 6 (Saturday)

  // Calculate the number of days to subtract from the current date to get to the start of the week (Monday)
  const daysToSubtract = currentDay === 0 ? 6 : currentDay - 1;
  const startOfWeek = new Date(date);
  startOfWeek.setDate(date.getDate() - daysToSubtract);

  // Calculate the end of the week (Sunday)
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  return {
    firstDay: formatD(startOfWeek),
    lastDay: formatD(endOfWeek)
  };
};

const getCurrentYearDates = () => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), 0, 1); // January 1st of the current year
  const lastDay = new Date(date.getFullYear(), 11, 31); // December 31st of the current year

  return { firstDay: formatD(firstDay), lastDay: formatD(lastDay) };
};

const getCurrentQuarterDates = () => {
  const date = new Date();
  const currentMonth = date.getMonth();
  let startMonth, endMonth;

  if (currentMonth >= 0 && currentMonth <= 2) {
    // First quarter (January - March)
    startMonth = 0;
    endMonth = 2;
  } else if (currentMonth >= 3 && currentMonth <= 5) {
    // Second quarter (April - June)
    startMonth = 3;
    endMonth = 5;
  } else if (currentMonth >= 6 && currentMonth <= 8) {
    // Third quarter (July - September)
    startMonth = 6;
    endMonth = 8;
  } else {
    // Fourth quarter (October - December)
    startMonth = 9;
    endMonth = 11;
  }

  const firstDay = new Date(date.getFullYear(), startMonth, 1);
  const lastDay = new Date(date.getFullYear(), endMonth + 1, 0);

  return { firstDay: formatD(firstDay), lastDay: formatD(lastDay) };
};

const showErrors = (error) => {
  if (typeof error === 'object') {
    const errorsText = Object.values(error)?.map((item, i) => `${item[0]}`);
    return (
      <>
        {errorsText.map((item, i) => (
          <div>{`${item}`}</div>
        ))}
      </>
    );
  }
};

/**
 * @param {*} period
 * get current and next quarter based on fiscal year.
 */
export const getFiscalQuarterAndYear = (period) => {
  // Current date
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();

  // Define fiscal period start months
  let periodStartMonths = {
    1: 0, // January
    4: 3, // April
    7: 6, // July
    10: 9 // October
  };

  // Determine fiscal year start month
  let fiscalYearStartMonth = periodStartMonths[period];

  // Determine current fiscal year
  if (currentDate.getMonth() < fiscalYearStartMonth) {
    currentYear--;
  }

  // Calculate current quarter number
  let currentQuarter = Math.floor(((currentDate.getMonth() - fiscalYearStartMonth + 12) % 12) / 3) + 1;

  // Calculate current quarter start date
  let currentQuarterStart = new Date(currentYear, fiscalYearStartMonth + (currentQuarter - 1) * 3, 1);

  // Calculate current quarter end date
  let currentQuarterEnd = new Date(currentQuarterStart);
  currentQuarterEnd.setMonth(currentQuarterEnd.getMonth() + 3);
  currentQuarterEnd.setDate(currentQuarterEnd.getDate() - 1);
  const { nextQuarterStartDate, nextQuarterEndDate } = getNextQuarter(new Date(currentQuarterEnd));
  // let nextQuarterYear = null;
  // console.log('period, currentQuarter: ', period, currentQuarter);
  // if (
  //   (Number(period) === 1 && currentQuarter === 4) ||
  //   (Number(period) === 4 && currentQuarter === 3) ||
  //   (Number(period) === 7 && currentQuarter >= 2) ||
  //   (Number(period) === 10 && currentQuarter >= 1)
  // ) {
  //   nextQuarterYear = currentYear + 1;
  // }
  return {
    currentQuarter: currentQuarter,
    quarterStartDate: currentQuarterStart,
    quarterEndDate: currentQuarterEnd,
    fiscalYear: currentYear,
    nextQuarterStartDate,
    nextQuarterEndDate,
    nextQuarter: currentQuarter === 4 ? 1 : currentQuarter + 1,
    nextQuarterYear: currentQuarter === 4 ? currentYear + 1 : currentYear
  };
};

function getNextQuarter(currentQuarterEnd) {
  // Calculate next quarter start date
  let nextQuarterStart = new Date(currentQuarterEnd);
  nextQuarterStart.setDate(nextQuarterStart.getDate() + 1);

  // Calculate next quarter end date
  let nextQuarterEnd = new Date(nextQuarterStart);
  nextQuarterEnd.setMonth(nextQuarterEnd.getMonth() + 3);
  nextQuarterEnd.setDate(nextQuarterEnd.getDate() - 1);

  return {
    nextQuarterStartDate: nextQuarterStart,
    nextQuarterEndDate: nextQuarterEnd
  };
}
/**
 * calculateNextQuarters:
 * function calculate next four quarter based on current quarter
 * which is calculate based on fiscal year period.
 * @param data: data means response of getFiscalQuarterAndYear function
 * @param period: means fiscal year period
 * @returns // below data function will return
 *  [quarter1: {
      quarter: Q1,
      year: 2024,
      update_frequency: null
    };]
 * 
 */
function calculateNextQuarters(data, period) {
  const result = {};
  let quarter = data.currentQuarter;
  let year = data.fiscalYear;

  for (let i = 0; i < 4; i++) {
    const key = `quarter${i + 1}`;
    result[key] = {
      fiscal_quarter: `Q${quarter}`,
      fiscal_year: year,
      update_frequency: null
    };

    if (quarter === 4) {
      year = data.fiscalYear + 1;
    }
    // Increment the quarter
    quarter++;
    if (quarter > 4) {
      quarter = 1;
      // year++;
    }
  }

  return result;
}

const getFiscalYears = (period = 1, fiscalYear) => {
  return `${fiscalYear}${Number(period) !== 1 ? '-' : ''}${Number(period) !== 1 ? (fiscalYear + 1).toString()?.slice(-2) : ''}`;
};

export {
  formatDate,
  convertTruncateText,
  getCurrentMonthDates,
  getCurrentWeekDates,
  getCurrentYearDates,
  getCurrentQuarterDates,
  showErrors,
  calculateNextQuarters,
  getFiscalYears
};
