import * as React from 'react';
const Info = (props) => (
  <svg
    fill="inherit"
    width="16px"
    height="16px"
    viewBox="0 0 0.64 0.64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0.325 0.095c0 0.034 0.025 0.057 0.057 0.057s0.057 -0.023 0.057 -0.057c0 -0.034 -0.025 -0.057 -0.057 -0.057 -0.031 0 -0.057 0.023 -0.057 0.057m-0.123 0.193c0 0.007 -0.001 0.023 0 0.033l0.05 -0.06c0.01 -0.011 0.022 -0.019 0.028 -0.017s0.009 0.009 0.007 0.016L0.205 0.532c-0.009 0.032 0.008 0.063 0.052 0.07 0.064 0 0.102 -0.043 0.139 -0.099 0 -0.007 0.002 -0.024 0.001 -0.034l-0.05 0.06c-0.01 0.011 -0.023 0.019 -0.029 0.017 -0.006 -0.002 -0.009 -0.008 -0.008 -0.014l0.083 -0.273c0.007 -0.035 -0.012 -0.066 -0.051 -0.07 -0.042 0 -0.103 0.044 -0.14 0.1" />
  </svg>
);
export default Info;
