// login
export const loginUrl = 'login';
export const refreshTokenUrl = 'refresh-access-token';
export const verifyLoginOtpUrl = 'verify-otp';
export const resendLoginOtpUrl = 'regenerate-otp';
export const resetPasswordUrl = 'reset-password';
export const verifyTokenUrl = 'verify-reset-pwdtoken';
export const updatePasswordUrl = 'update-password';
export const logoutUrl = 'logout';
// user detail
export const getUserDetailsUrl = 'user/profile-details';
// tsr user
export const trsUserListingsUrl = 'users/listing';
export const addTRSUserUrl = 'users';
export const uploadProfileImageUrl = 'upload-image';
export const verifyNewUserTokenUrl = 'verify-new-user';
export const updateNewUserPasswordUrl = 'set-user-password';
export const tsrResendInviteNonAcceptingUrl = 'users/resend-invite';

// dashboard
export const API_dashboardStaticsUrl = 'dashboard-count';
export const API_nonAcceptingUsersUrl = 'tenant-users/listing';
export const API_resendInviteNonAcceptingUrl = 'tenant-users/resend-invite';
export const API_usersByLocationUrl = 'location-login-counts';
// company
export const companyListingsUrl = 'listing';
export const archiveCompanyUrl = 'archive';
export const deleteCompanyForAdminUrl = 'delete-request';
export const deleteCompanyForSuperAdminUrl = 'tenants';
export const restoreCompanyUrl = 'restore';
export const tenantApprovalUrl = 'approval';
export const companyDetailsUrl = 'tenants';
export const createCompanyUrl = 'tenants';
export const updateCompanyUrl = 'tenants';
export const companyWiseUsersListings = 'tenant-users/listing';
export const tenantUserFormDetailUrl = 'tenants';
export const tenantUsersUrl = 'tenant-users';
export const bulkImportTenantUserUrl = 'tenant-users/bulk-create';
export const remindToLoginUrl = 'tenant-users/remind-to-login';

// export const userProfileUrl = "/cxpay-api-production/public/api/get-profile";
// export const refreshTokenUrl = "/cxpay-api-production/public/api/refresh-token";
